//
// _horizontal.scss
//

.topnav {
	background: $topnav-bg;
	padding: 0 calc(#{$grid-gutter-width} / 2);
	box-shadow: $box-shadow-lg;
	margin-top: $header-height;
	position: fixed;
	left: 0;
	right: 0;
	z-index: 10;

	.topnav-menu {
		margin: 0;
		padding: 0;
	}

	.navbar-nav {
		.nav-link {
			font-size: 14.5px;
			position: relative;
			padding: 1rem 1.3rem;
			color: rgba($white, 0.5);
			i {
				font-size: 15px;
			}
			&:focus,
			&:hover {
				color: $white;
				background-color: transparent;
			}
		}

		> .dropdown {
			&.active {
				> a {
					color: rgba($white, 0.9) !important;
				}
			}
		}

		.dropdown-item {
			color: $sidebar-menu-item-color;
			&.active,
			&:hover {
				color: $sidebar-menu-item-active-color;
				background-color: transparent;
			}
		}

		.nav-item {
			.nav-link.active {
				color: $greenplitz;
			}
		}

		.dropdown {
			&.active {
				> a {
					color: $sidebar-menu-item-active-color;
					background-color: transparent;
				}
			}
		}
	}
}

@include media-breakpoint-up(xl) {
	body[data-layout='horizontal'] {
		.container-fluid,
		.navbar-header {
			max-width: 85%;
		}
	}
}

@include media-breakpoint-up(lg) {
	.topnav {
		.navbar-nav {
			.nav-item {
				&:first-of-type {
					.nav-link {
						padding-left: 0;
					}
				}
			}
		}

		.dropdown-item {
			padding: 0.5rem 1.5rem;
			min-width: 180px;
		}

		.dropdown {
			&.mega-dropdown {
				// position: static;
				.mega-dropdown-menu {
					left: 0px;
					right: auto;
				}
			}
			.dropdown-menu {
				margin-top: 0;
				border-radius: 0 0 15px 15px;
				overflow: visible;

				.arrow-down {
					&::after {
						right: 15px;
						transform: rotate(-135deg) translateY(-50%);
						position: absolute;
					}
				}

				.dropdown {
					.dropdown-menu {
						border-radius: 15px;
						position: absolute;
						top: 0;
						left: 100%;
						display: none;
					}
				}
			}

			&:hover {
				> .dropdown-menu {
					display: block;
				}
			}
		}

		.dropdown:hover > .dropdown-menu > .dropdown:hover > .dropdown-menu {
			display: block;
		}
	}

	.navbar-toggle {
		display: none;
	}
}

.arrow-down {
	display: inline-block;

	&:after {
		border-color: initial;
		border-style: solid;
		border-width: 0 0 1px 1px;
		content: '';
		height: 0.4em;
		display: inline-block;
		right: 5px;
		top: 50%;
		margin-left: 10px;
		transform: rotate(-45deg) translateY(-50%);
		transform-origin: top;
		transition: all 0.3s ease-out;
		width: 0.4em;
	}
}

@include media-breakpoint-down(lg) {
	.topnav-menu {
		.navbar-nav {
			li {
				&:last-of-type {
					.dropdown {
						.dropdown-menu {
							right: 100%;
							left: auto;
						}
					}
				}
			}
		}
	}
}

@include media-breakpoint-down(md) {
	.dropdown-menu.show {
		width: 90%;
	}

	.topnav {
		max-height: 100vh;
		overflow-y: auto;
		overflow-x: hidden;
		width: 100%;
		padding: 0;
		.navbar-nav {
			.nav-link {
				padding: 0.75rem 1.1rem;
			}
		}

		.dropdown {
			.dropdown-menu {
				background-color: transparent;
				border: none;
				box-shadow: none;
				padding-left: 15px;
			}

			.dropdown-item {
				position: relative;
				background-color: transparent;

				&.active,
				&:active {
					color: $primary;
				}
			}
		}

		.arrow-down {
			&::after {
				right: 15px;
				position: absolute;
			}
		}
	}
}
