@import './icons.scss';
@import './fontawesome.scss';
@import './solid.scss';
@import './light.scss';
@import './regular.scss';
@import './thin.scss';
@import './v4-shims.scss';
@import './brands.scss';
@import './duotone.scss';

// Light Theme
@import './bootstrap.scss';
@import './app.scss';

body {
  font-family: $font-family-sans-serif;

  * {
    outline: none;
  }
}
#sidebar-menu {
  ul {
    li {
      a {
        i {
          min-width: 2rem;
        }
      }
    }
  }
}

[class*=' ti-'],
[class^='ti-'] {
  font-family: themify;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.noti-icon .badge {
  left: 23px;
}
.dataTables_filter,
.dataTables_paginate {
  float: right;
}
.rdw-editor-main {
  border: 1px solid $gray-300;
  height: 239px;
}
.dz-message {
  text-align: center;
}
// kanban board
.react-kanban-column {
  height: 100%;
  min-height: 28px;
  display: inline-block;
  padding: 15px;
  border-radius: 2px;
  background-color: rgb(238, 238, 238);
  margin: 5px;
  vertical-align: top;
}

.fcYNFc,
.sc-AxmLO.gmtmqV {
  background-color: $card-bg !important;
  margin: 0 20px 0 0 !important;
}
.task-box {
  border: 1px solid $border-color;
}
.react-datepicker-wrapper {
  width: 100% !important;
}
.ReactModal__Overlay {
  z-index: 1001 !important;
}
.chat-conversation .right .conversation-list {
  margin-right: 15px;
}
.external-event {
  &:hover {
    cursor: pointer;
  }
}
a:hover {
  cursor: pointer;
}
.rating-container {
  background-color: transparent !important;
}
.input-group-append {
  z-index: 0;
}
.input-color {
  color: $input-color !important;
}
.sketch-picker {
  position: absolute;
  z-index: 1;
}
.rangeslider__fill {
  background-color: $primary !important;
}

.flatpickr-months .flatpickr-month {
  background: $primary !important;
}
.flatpickr-weekdays {
  background: $primary !important;
}
span.flatpickr-weekday {
  background: $primary !important;
}
.flatpickr-current-month .flatpickr-monthDropdown-months {
  background: $primary !important;
}
span.flatpickr-weekday {
  color: $white !important;
}
.flatpickr-day.selected {
  background: $primary !important;
}

//File-manager
.file-manager {
  .table td {
    padding: 0.35rem;
  }
}

//Crypto enteries
.bs-select select {
  width: auto !important;
}

//Authenication carousel
.slider_css {
  .slide {
    background: none !important;
  }

  // .control-dots {
  //   margin-top: 35px !important;
  // }

  .carousel-status,
  .control-next,
  .control-prev {
    display: none !important;
  }
}
.carousel {
  .control-dots {
    margin: -5px 0px;
  }
  .dot {
    background: #556ee6 !important;
    width: 9px !important;
    height: 9px !important;
  }
}

// calendar modal
.close {
  background: transparent;
  border: 0;
  font-size: 10px;
  padding: 1.35rem 1.25rem;
  background: transparent escape-svg($btn-close-bg-dark) center /
    $btn-close-width auto no-repeat;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.5;
  width: 1em;
  height: 1em;
  z-index: 2;
  span {
    display: none;
  }
}

.wizard .actions {
  position: relative !important;
  display: block !important;
  text-align: right !important;
  width: 100% !important;
}
.wizard .actions > ul {
  display: block !important;
  text-align: right !important;
  padding-left: 0 !important;
}
.wizard .actions > ul > li {
  display: inline-block !important;
  margin: 0 0.5em !important;
}

//Dropdown
.dropup .dropdown-menu {
  top: auto !important;
  bottom: 100% !important;
  transform: translate3d(5px, 5px, 0px) !important;
}

//form editor
.rdw-editor-toolbar,
.rdw-editor-main {
  border: 1px solid #ccc !important;
}

//Calendar
.app-calendar .btn {
  text-transform: capitalize;
}
.dropzone:focus {
  outline: none !important ;
}
//dropdown
.dropdown-menu.dropdown-menu-right {
  right: 0 !important;
  left: auto !important;
}
.card-columns {
  column-count: 3;
  grid-column-gap: 1.25rem;
  -webkit-column-gap: 1.25rem;
  grid-column-gap: 24px;
  column-gap: 24px;
  orphans: 1;
  widows: 1;
}
.ion-ios-checkmark-circle-outline {
  font-size: 3.5rem;
  line-height: 56px;
}
label {
  margin-bottom: 0.5rem;
}
.DraftEditor-editorContainer {
  margin-left: 14px !important;
}
.wizard .steps .nav-link {
  background-color: #daddf5;
  color: #626ed4;
}
.wizard .steps .nav-link .number {
  border-color: #626ed4;
}

// Theme Custom Scss for react version
.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y,
.ps__rail-y {
  width: auto !important;
}
.rdw-editor-main {
  border: 1px solid $card-border-color !important;
  border-radius: 4px !important;
  min-height: 130px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.dataTables_filter {
  float: right;
}

.dataTables_paginate .pagination {
  float: right;
}

.rdw-editor-wrapper {
  min-height: 100px !important;
}

.ReactModal__Overlay {
  z-index: 10001 !important;
}

.react-datepicker-wrapper {
  .form-control {
    width: 100% !important;
  }
}

.react-datepicker-popper[data-placement^='bottom'] {
  z-index: 999;
}

.needsclick {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropzone {
  h3 {
    text-align: center !important;
  }
  div {
    min-height: 200px !important;
  }
  min-height: 200px !important;
}

input[type='radio'] + label {
  cursor: pointer !important;
}

.input-color {
  color: $input-color !important;
}
.rangeslider__fill {
  background-color: $primary !important;
}
.rating-container {
  background-color: transparent !important;
}

.button-reveal-password {
  border: none;
  background-color: transparent;
  position: absolute;
  right: 0.5rem;
  top: 35%;
  transform: translateY(-50%);
  z-index: 5;
}

.button-reveal-password .mdi-eye,
.button-reveal-password .mdi-eye-off {
  font-size: 22px;
}

.change-password input::-webkit-contacts-auto-fill-button,
.change-password input::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  position: absolute;
  right: 0;
}

.register-page input::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  position: absolute;
  right: 0;
}

@include media-breakpoint-down(md) {
  .bs-select,
  .mdb-datatable-filter {
    width: 100%;

    label {
      width: 100%;
    }

    select {
      margin-left: 0 !important;
    }
  }

  .table-bordered th:nth-child(2),
  .table-bordered td:nth-child(2) {
    min-width: 200px;
  }

  .table-bordered th:nth-child(4),
  .table-bordered td:nth-child(4) {
    min-width: 200px;
  }

  .fc-header-toolbar {
    display: flex;
    flex-direction: column;
  }
}

// Cards for all post types.

.plitz-cards .card-body {
  height: 100%;
}

.card-body {
  .text-muted {
    font-size: 0.6rem;
  }
}

.card-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
}

.card-status-area {
  display: flex;
  justify-content: flex-end;
}

.card-actions-area {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  border-top: solid 1px rgba($primary, 0.05);

  time {
    font-weight: 600;
  }
}

.plitz-cards .badge {
  padding: 0.7em 1em 0.5em;
}

.plitz-cards .current-page button {
  font-weight: 700;
  color: $dark;
}

.plitz-cards .pagination {
  justify-content: center;
}

.card-extra-info {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.card-extra-info h5 {
  width: 100%;
}

.categories {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  margin-top: 5px;

  span {
    margin-right: 5px;
  }

  span:last-child {
    margin-right: 0;
  }
}

.card-img-top {
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 20px 0 0 20px;

  .actual-image {
    object-fit: cover;
    min-height: 100%;
    min-width: 100%;
    max-width: 100%;
    display: block;
    position: relative;
  }
}

.for-testimonials {
  width: 20em;
  height: 20em;
  margin: auto;
}

.for-testimonials-list {
  width: 10em;
  height: 10em;
  top: 50%;
  transform: translateY(-50%);
}

.stars-container {
  display: flex;
  align-items: center;

  button {
    color: $primary;
    padding: 0.375rem 0.6vw;
  }
}

@media screen and (max-width: 1239px) {
  .stars-container {
    button {
      padding: 0.375rem 0.2vw;
    }
  }
}

@media screen and (max-width: 959px) {
  .card-content-area {
    text-align: center;

    .flex-horizontal {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .display-1 {
      font-size: 4rem;
    }

    .display-4 {
      font-size: 2rem;
    }
  }
}

@media screen and (max-width: 767px) {
  .card-img-top {
    border-radius: 20px 20px 0 0;
  }

  .stars-container {
    button {
      padding: 0.375rem 0.9vw;
    }
  }
}

@media screen and (max-width: 600px) {
  .card-actions-area {
    flex-direction: column;
    text-align: center;
  }

  .display-1 {
    font-size: 3.5rem;
  }

  .card-extra-info {
    flex-direction: column;
    justify-content: center;
  }

  .categories {
    width: 100%;
    justify-content: center;
    margin-top: 20px;
  }

  .for-testimonials {
    width: 10em;
    height: 10em;
    margin: auto;
  }

  .for-testimonials-list {
    width: 7em;
    height: 7em;
    top: 50%;
    transform: translateY(-50%);
  }
}

// Post forms
.plitz-forms .mt-auto {
  margin-top: 0 !important;
  line-height: 1.7 !important;
}

.editorClassName {
  min-height: 200px !important;
}

.cat-editor-html {
  min-height: 200px !important;
}

.setting-bottom-divider {
  border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
}

.editor-html {
  min-height: 250px !important;
}

.remove-gal {
  position: relative;
}

.remove-gal .remove-image {
  position: absolute;
  background-color: #fff;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  top: 0;
  right: 0;
  z-index: 2;
}

.remove-gal img {
  position: relative;
}

.remove-gal .remove-overlay {
  position: absolute;
  width: calc(100% - 1.5rem);
  top: 0;
  bottom: 0;
  opacity: 0;
  color: #000;
  font-size: 10px !important;
  text-transform: uppercase;
  background-color: rgba($color: #ffffff, $alpha: 0);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
}

.remove-gal:hover .remove-overlay {
  opacity: 1;
  background-color: rgba($color: #ffffff, $alpha: 0.7);
  transition: all 0.3s ease;
}

// Dashboard details
.plitz-flex-align {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  transition: all 0.3s ease;
}

.plitz-flex-align p,
.plitz-flex-align .float-right {
  transition: all 0.3s ease;
}

.plitz-flex-align:hover p,
.plitz-flex-align:hover .float-right {
  color: rgba($color: #ffffff, $alpha: 0.9) !important;
  transition: all 0.3s ease;
}

.pagination-arrow {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  transform: scale(1.1);
  margin-left: -1px;
  line-height: 1.25;
  color: #6a479c;
  background-color: #fff;
  border: 1px solid #ced4da;
  transition: all 0.3s ease;
  z-index: 1;
}

.pagination-arrow:hover {
  z-index: 2;
  color: #462f67;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #ced4da;
  transition: all 0.3s ease;
}

// Global modifications
.float-left {
  float: left;
}

.float-right {
  float: right;
}

.mr-3,
.mx-3 {
  margin-right: 1rem;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem;
}

.text-white {
  color: $white;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.uiFormControl-root {
  position: relative;
}

.MuiOutlinedInput-root {
  border-radius: 10px !important;
  margin-bottom: 1.5rem;
}

.MuiOutlinedInput-root,
.MuiInputLabel-root {
  font-family: $font-family-sans-serif !important;
}

.MuiFormHelperText-root {
  margin-top: -1.2rem !important;
  margin-bottom: 1.5rem !important;
}

.welcome-text strong {
  font-weight: 700;
}

.dropdown button svg,
#vertical-menu-btn svg {
  width: 100%;
  height: auto;
  max-width: 22px;
  max-height: 22px;
  // color: $blueplitz;
  // fill: $blueplitz;
}

body[data-topbar='dark'] .dropdown button svg,
body[data-topbar='dark'] #vertical-menu-btn svg {
  color: $header-dark-item-color;
  fill: $header-dark-item-color;
}

.media {
  display: flex;
  align-items: flex-start;
}

.vertical-align {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.create-shortcut {
  color: $blue;
  font-size: 12px;
  text-transform: uppercase;
  background-color: $greenplitz;
  border: none;
  transition: all 0.3s ease;

  &:hover {
    color: $greenplitz;
    background-color: $blue;
    transition: all 0.3s ease;
  }
}

.custom-radio {
  display: flex;
  align-items: center;
  margin-bottom: 0.3rem;

  input {
    margin-right: 0.5rem;
  }

  .custom-control-label {
    font-size: 12px;
    text-transform: uppercase;
    margin-bottom: 0;
  }
}

.option-label {
  font-size: 12px;
  color: $gray-500;
}

#vertical-menu-btn {
  display: block;
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.email {
  .btn-toolbar {
    align-items: center;
    justify-content: space-between;
  }
}

.plitz__menu {
  z-index: 999;
}

.credits-plitz {
  display: flex;
  align-items: center;
}

//
// _helper.scss
//

.font-size-10 {
  font-size: 10px !important;
}

.font-size-11 {
  font-size: 11px !important;
}

.font-size-12 {
  font-size: 12px !important;
}

.font-size-13 {
  font-size: 13px !important;
}

.font-size-14 {
  font-size: 14px !important;
}

.font-size-15 {
  font-size: 15px !important;
}

.font-size-16 {
  font-size: 16px !important;
}

.font-size-17 {
  font-size: 17px !important;
}

.font-size-18 {
  font-size: 18px !important;
}

.font-size-20 {
  font-size: 20px !important;
}

.font-size-22 {
  font-size: 22px !important;
}

.font-size-24 {
  font-size: 24px !important;
}

// Font weight help class

.fw-medium {
  font-weight: $fw-medium !important;
}

.fw-semibold {
  font-weight: $font-weight-semibold !important;
}

// flex-1 help class

.flex-1 {
  flex: 1;
}

.flex-horizontal {
  display: flex;
  align-items: center;
}

.flex-horizontal span {
  margin-bottom: 0.5rem;
  margin-left: 0.2rem;
}

// Social

.social-list-item {
  height: 2rem;
  width: 2rem;
  line-height: calc(2rem - 2px);
  display: block;
  border: 1px solid $gray-500;
  border-radius: 50%;
  color: $gray-500;
  text-align: center;
}

.w-xs {
  min-width: 80px;
}
.w-sm {
  min-width: 95px;
}
.w-md {
  min-width: 110px;
}
.w-lg {
  min-width: 140px;
}

.w-xl {
  min-width: 160px;
}

.rounded {
  border-radius: 20px !important;
}

.apexcharts-tooltip-text-y-value::after {
  content: '%';
}

@media screen and (max-width: 459px) {
  .directory-card .card-body .d-flex {
    flex-wrap: wrap;
  }

  .flex-1 {
    flex: 0 0 calc(70% - 1rem);
    width: calc(70% - 1rem);
  }

  .social-links {
    display: flex;
    flex-direction: row;

    li {
      margin-right: 10px;
    }
  }
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $prop, $abbrev in (margin: m, padding: p) {
      @each $size, $length in $spacers {
        .#{$abbrev}#{$infix}-#{$size} {
          #{$prop}: $length !important;
        }
        .#{$abbrev}t#{$infix}-#{$size},
        .#{$abbrev}y#{$infix}-#{$size} {
          #{$prop}-top: $length !important;
        }
        .#{$abbrev}r#{$infix}-#{$size},
        .#{$abbrev}x#{$infix}-#{$size} {
          #{$prop}-right: $length !important;
        }
        .#{$abbrev}b#{$infix}-#{$size},
        .#{$abbrev}y#{$infix}-#{$size} {
          #{$prop}-bottom: $length !important;
        }
        .#{$abbrev}l#{$infix}-#{$size},
        .#{$abbrev}x#{$infix}-#{$size} {
          #{$prop}-left: $length !important;
        }
      }
    }

    // Negative margins (e.g., where `.mb-n1` is negative version of `.mb-1`)
    @each $size, $length in $spacers {
      @if '#{$size}' != '0' {
        .m#{$infix}-n#{$size} {
          margin: -$length !important;
        }
        .mt#{$infix}-n#{$size},
        .my#{$infix}-n#{$size} {
          margin-top: -$length !important;
        }
        .mr#{$infix}-n#{$size},
        .mx#{$infix}-n#{$size} {
          margin-right: -$length !important;
        }
        .mb#{$infix}-n#{$size},
        .my#{$infix}-n#{$size} {
          margin-bottom: -$length !important;
        }
        .ml#{$infix}-n#{$size},
        .mx#{$infix}-n#{$size} {
          margin-left: -$length !important;
        }
      }
    }

    // Some special margin utils
    .m#{$infix}-auto {
      margin: auto !important;
    }
    .mt#{$infix}-auto,
    .my#{$infix}-auto {
      margin-top: auto !important;
    }
    .mr#{$infix}-auto,
    .mx#{$infix}-auto {
      margin-right: auto !important;
    }
    .mb#{$infix}-auto,
    .my#{$infix}-auto {
      margin-bottom: auto !important;
    }
    .ml#{$infix}-auto,
    .mx#{$infix}-auto {
      margin-left: auto !important;
    }
  }
}

// Buttons effects
.waves-effect {
  position: relative;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}
.waves-effect .waves-ripple {
  position: absolute;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  margin-top: -50px;
  margin-left: -50px;
  opacity: 0;
  background: rgba(0, 0, 0, 0.2);
  background: -webkit-radial-gradient(
    rgba(0, 0, 0, 0.2) 0,
    rgba(0, 0, 0, 0.3) 40%,
    rgba(0, 0, 0, 0.4) 50%,
    rgba(0, 0, 0, 0.5) 60%,
    rgba(255, 255, 255, 0) 70%
  );
  background: -o-radial-gradient(
    rgba(0, 0, 0, 0.2) 0,
    rgba(0, 0, 0, 0.3) 40%,
    rgba(0, 0, 0, 0.4) 50%,
    rgba(0, 0, 0, 0.5) 60%,
    rgba(255, 255, 255, 0) 70%
  );
  background: -moz-radial-gradient(
    rgba(0, 0, 0, 0.2) 0,
    rgba(0, 0, 0, 0.3) 40%,
    rgba(0, 0, 0, 0.4) 50%,
    rgba(0, 0, 0, 0.5) 60%,
    rgba(255, 255, 255, 0) 70%
  );
  background: radial-gradient(
    rgba(0, 0, 0, 0.2) 0,
    rgba(0, 0, 0, 0.3) 40%,
    rgba(0, 0, 0, 0.4) 50%,
    rgba(0, 0, 0, 0.5) 60%,
    rgba(255, 255, 255, 0) 70%
  );
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  -webkit-transition-property: -webkit-transform, opacity;
  -moz-transition-property: -moz-transform, opacity;
  -o-transition-property: -o-transform, opacity;
  transition-property: transform, opacity;
  -webkit-transform: scale(0) translate(0, 0);
  -moz-transform: scale(0) translate(0, 0);
  -ms-transform: scale(0) translate(0, 0);
  -o-transform: scale(0) translate(0, 0);
  transform: scale(0) translate(0, 0);
  pointer-events: none;
}
.waves-effect.waves-light .waves-ripple {
  background: rgba(255, 255, 255, 0.4);
  background: -webkit-radial-gradient(
    rgba(255, 255, 255, 0.2) 0,
    rgba(255, 255, 255, 0.3) 40%,
    rgba(255, 255, 255, 0.4) 50%,
    rgba(255, 255, 255, 0.5) 60%,
    rgba(255, 255, 255, 0) 70%
  );
  background: -o-radial-gradient(
    rgba(255, 255, 255, 0.2) 0,
    rgba(255, 255, 255, 0.3) 40%,
    rgba(255, 255, 255, 0.4) 50%,
    rgba(255, 255, 255, 0.5) 60%,
    rgba(255, 255, 255, 0) 70%
  );
  background: -moz-radial-gradient(
    rgba(255, 255, 255, 0.2) 0,
    rgba(255, 255, 255, 0.3) 40%,
    rgba(255, 255, 255, 0.4) 50%,
    rgba(255, 255, 255, 0.5) 60%,
    rgba(255, 255, 255, 0) 70%
  );
  background: radial-gradient(
    rgba(255, 255, 255, 0.2) 0,
    rgba(255, 255, 255, 0.3) 40%,
    rgba(255, 255, 255, 0.4) 50%,
    rgba(255, 255, 255, 0.5) 60%,
    rgba(255, 255, 255, 0) 70%
  );
}
.waves-effect.waves-classic .waves-ripple {
  background: rgba(0, 0, 0, 0.2);
}
.waves-effect.waves-classic.waves-light .waves-ripple {
  background: rgba(255, 255, 255, 0.4);
}
.waves-notransition {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}
.waves-button,
.waves-circle {
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-mask-image: -webkit-radial-gradient(circle, white 100%, black 100%);
}
.waves-button,
.waves-button:hover,
.waves-button:visited,
.waves-button-input {
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  border: none;
  outline: none;
  color: inherit;
  background-color: rgba(0, 0, 0, 0);
  font-size: 1em;
  line-height: 1em;
  text-align: center;
  text-decoration: none;
  z-index: 1;
}
.waves-button {
  padding: 0.85em 1.1em;
  border-radius: 0.2em;
}
.waves-button-input {
  margin: 0;
  padding: 0.85em 1.1em;
}
.waves-input-wrapper {
  border-radius: 0.2em;
  vertical-align: bottom;
}
.waves-input-wrapper.waves-button {
  padding: 0;
}
.waves-input-wrapper .waves-button-input {
  position: relative;
  top: 0;
  left: 0;
  z-index: 1;
}
.waves-circle {
  text-align: center;
  width: 2.5em;
  height: 2.5em;
  line-height: 2.5em;
  border-radius: 50%;
}
.waves-float {
  -webkit-mask-image: none;
  -webkit-box-shadow: 0px 1px 1.5px 1px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 1px 1.5px 1px rgba(0, 0, 0, 0.12);
  -webkit-transition: all 300ms;
  -moz-transition: all 300ms;
  -o-transition: all 300ms;
  transition: all 300ms;
}
.waves-float:active {
  -webkit-box-shadow: 0px 8px 20px 1px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 8px 20px 1px rgba(0, 0, 0, 0.3);
}
.waves-block {
  display: block;
}

.waves-effect.waves-light {
  .waves-ripple {
    background-color: rgba($white, 0.4);
  }
}

.waves-effect.waves-primary {
  .waves-ripple {
    background-color: rgba($primary, 0.4);
  }
}
.waves-effect.waves-success {
  .waves-ripple {
    background-color: rgba($success, 0.4);
  }
}
.waves-effect.waves-info {
  .waves-ripple {
    background-color: rgba($info, 0.4);
  }
}
.waves-effect.waves-warning {
  .waves-ripple {
    background-color: rgba($warning, 0.4);
  }
}
.waves-effect.waves-danger {
  .waves-ripple {
    background-color: rgba($danger, 0.4);
  }
}

.donut-title {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;

  p,
  h6 {
    margin-bottom: 0;
  }
}

// Plitz Promos Carousel

.plitz-promos {
  height: 325px;

  .carousel-indicators {
    z-index: 999;
    left: 0;
    right: auto;
    bottom: 10px;
    border-radius: 5px;
    overflow: hidden;

    button {
      background-color: rgba(0, 0, 0, 0.8);
    }

    li {
      display: block;
      width: 30px;
      height: 10px;
    }

    .active {
      background-color: $greenplitz;
    }
  }

  .slider-image {
    position: relative;
    width: 100%;
    height: 325px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    img {
      position: relative;
      display: block;
      object-fit: cover;
      max-height: 100%;
      min-height: 100%;
    }
  }

  .slider-info {
    padding: 30px 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h4 {
      font-size: 18px;
    }

    .slider-content {
      font-size: 10px;

      p {
        font-size: 10px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .plitz-promos {
    height: 100%;

    .carousel-indicators {
      left: 50%;
      transform: translateX(-50%);
      bottom: 10px;
      margin: auto;
    }

    .slider-image {
      height: 250px;
    }
  }
}
