//
// avatar.scss
//

.avatar-xs {
	height: 2.3rem;
	width: 2.3rem;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;

	.actual-image {
		display: block;
		object-fit: cover;
		min-width: 100%;
		min-height: 100%;
		width: auto;
		height: auto;
	}
}

.avatar-sm {
	height: 3rem;
	width: 3rem;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	border: 1px solid rgba($color: #000000, $alpha: 0.1);

	.actual-image {
		display: block;
		object-fit: cover;
		min-width: 100%;
		min-height: 100%;
		width: auto;
		height: auto;
	}
}

.avatar-md {
	height: 4.5rem;
	width: 4.5rem;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	border: 1px solid rgba($color: #000000, $alpha: 0.1);

	.actual-image {
		display: block;
		object-fit: cover;
		min-width: 100%;
		min-height: 100%;
		width: auto;
		height: auto;
	}
}

.avatar-lg {
	height: 5.5rem;
	width: 5.5rem;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	border: 1px solid rgba($color: #000000, $alpha: 0.1);

	.actual-image {
		display: block;
		object-fit: cover;
		min-width: 100%;
		min-height: 100%;
		width: auto;
		height: auto;
	}
}

.avatar-xl {
	height: 7.5rem;
	width: 7.5rem;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	margin: 0 auto;

	.actual-image {
		display: block;
		object-fit: cover;
		min-width: 100%;
		min-height: 100%;
		width: auto;
		height: auto;
	}
}

.avatar-title {
	align-items: center;
	background-color: $primary;
	color: $white;
	display: flex;
	font-weight: $fw-medium;
	height: 100%;
	justify-content: center;
	width: 100%;
}
