// 
// _card.scss
// 

.card {
  background-color: rgba($color: #FFFFFF, $alpha: 0.7);
  margin-bottom: $grid-gutter-width;
  box-shadow: $box-shadow;
  backdrop-filter: blur(10px) saturate(100%) contrast(50%) brightness(145%);
  border-radius: 20px;
}

.card-drop {
  color: $body-color;
}

.card-title {
  font-size: 18px;
}

.card-title-desc {
  color: $card-title-desc;
  margin-bottom: 24px;  
}

