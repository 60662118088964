//
// Responsive Table
//

.table-rep-plugin {
	.btn-toolbar {
		display: block;
	}
	.table-responsive {
		border: none !important;
	}
	.btn-group {
		.btn-default {
			background-color: $light;
			color: $dark;
			border: 1px solid $light;
			&.btn-primary {
				background-color: $primary;
				border-color: $primary;
				color: $white;
				box-shadow: 0 0 0 2px rgba($primary, 0.5);
			}
		}
		&.pull-right {
			float: right;
			.dropdown-menu {
				right: 0;
				transform: none !important;
				top: 100% !important;
			}
		}
	}
	tbody {
		th {
			font-size: 14px;
			font-weight: normal;
		}
	}
	.checkbox-row {
		padding-left: 40px;

		label {
			display: inline-block;
			padding-left: 5px;
			position: relative;
			&::before {
				-o-transition: 0.3s ease-in-out;
				-webkit-transition: 0.3s ease-in-out;
				background-color: $white;
				border-radius: 3px;
				border: 1px solid $gray-300;
				content: '';
				display: inline-block;
				height: 17px;
				left: 0;
				margin-left: -20px;
				position: absolute;
				transition: 0.3s ease-in-out;
				width: 17px;
				outline: none !important;
			}
			&::after {
				color: $gray-200;
				display: inline-block;
				font-size: 11px;
				height: 16px;
				left: 0;
				margin-left: -20px;
				padding-left: 3px;
				padding-top: 1px;
				position: absolute;
				top: -1px;
				width: 16px;
			}
		}
		input[type='checkbox'] {
			cursor: pointer;
			opacity: 0;
			z-index: 1;
			outline: none !important;

			&:disabled + label {
				opacity: 0.65;
			}
		}
		input[type='checkbox']:focus + label {
			&::before {
				outline-offset: -2px;
				outline: none;
			}
		}
		input[type='checkbox']:checked + label {
			&::after {
				content: '\f00c';
				font-family: 'Font Awesome 6 Pro';
				font-weight: 300;
				font-weight: 900;
			}
		}
		input[type='checkbox']:disabled + label {
			&::before {
				background-color: $gray-100;
				cursor: not-allowed;
			}
		}
		input[type='checkbox']:checked + label {
			&::before {
				background-color: $primary;
				border-color: $primary;
			}
			&::after {
				color: $white;
			}
		}
	}

	.fixed-solution {
		.sticky-table-header {
			top: $header-height !important;
			background-color: $primary;
			table {
				color: $white;
			}
		}
	}
}
