//
// Widgets.scss
//

.mini-stat {
	.mini-stat-img {
		width: 58px;
		height: 58px;
		color: $greenplitz;
		border-radius: 4px;
		display: flex;
		justify-content: center;
		align-items: center;

		img {
			max-width: 32px;
		}
	}
	.mini-stat-label {
		position: absolute;
		right: 0;
		top: 18px;
		padding: 2px 10px 2px 32px;
		clip-path: polygon(100% 0, 100% 50%, 100% 100%, 0% 100%, 24% 50%, 6% 0);
	}
}

/* Activity */
.activity-feed {
	padding: 15px 15px 0 15px;
	list-style: none;

	.feed-item {
		position: relative;
		padding-bottom: 29px;
		padding-left: 30px;
		border-left: 2px solid $gray-200;

		&:last-child {
			border-color: transparent;
		}

		&::after {
			content: '';
			display: block;
			position: absolute;
			top: -4px;
			left: -9px;
			width: 16px;
			height: 16px;
			border-radius: 30px;
			background: $primary;
		}
		.date {
			display: block;
			position: relative;
			top: -5px;
			text-transform: uppercase;
			font-size: 13px;
		}
		.activity-text {
			position: relative;
			top: -3px;
		}
	}
}

//   Sales Report

.ct-chart.wid {
	height: 240px;
}

.ct-chart.wid .ct-slice-donut {
	stroke-width: 14px !important;
}

/* peity */
.wid-peity {
	border-bottom: 1px solid $gray-200;
}

//   Chat

.conversation-list {
	list-style: none;
	padding: 0px 10px;
	max-height: 350px;
	li {
		margin-bottom: 24px;
	}
	.chat-avatar {
		width: 40px;
		display: inline-block;
		text-align: center;
		float: left;
		.time {
			font-size: 12px;
			font-style: normal;
		}
	}
	.ctext-wrap {
		.user-name {
			display: block;
			font-weight: 600;
			position: relative;
			font-size: 12px;
			color: $primary;
		}
		p {
			color: darken($primary, 18%);
		}
	}
	.conversation-text {
		display: inline-block;
		font-size: 12px;
		float: left;
		margin-left: 22px;
		width: 70%;
	}
	.ctext-wrap {
		padding: 10px 18px;
		background: lighten($primary, 32%);
		border-radius: 0px 7px 7px 7px;
		position: relative;
		display: inline-block;
		&:after {
			content: ' ';
			position: absolute;
			right: 100%;
			top: 0;
			border: solid transparent;
			border-right-color: lighten($primary, 32%);
			border-top-color: lighten($primary, 32%);
			border-width: 5px;
		}
		p {
			margin: 0px;
			padding-top: 3px;
		}
	}
	.odd {
		.chat-avatar {
			float: right !important;
		}
		.conversation-text {
			width: 70% !important;
			margin-right: 22px;
			text-align: right;
			float: right !important;
		}
		.ctext-wrap {
			background: lighten($gray-200, 2%) !important;
			border-radius: 7px 0px 7px 7px;
			p {
				color: $gray-700;
			}
			&:after {
				left: 100% !important;
				top: 0 !important;
				border-color: rgba(238, 238, 242, 0) !important;
				border-left-color: lighten($gray-200, 2%) !important;
				border-top-color: lighten($gray-200, 2%) !important;
			}
		}
	}
}
