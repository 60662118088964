/*!
 * Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 * Copyright 2022 Fonticons, Inc.
 */
@import './custom/plugins/icons/functions';
@import './custom/plugins/icons/variables';

:root,
:host {
	--#{$fa-css-prefix}-font-brands: normal 400 1em/1 'Font Awesome 6 Brands';
}

@font-face {
	font-family: 'Font Awesome 6 Brands';
	font-style: normal;
	font-weight: 400;
	font-display: $fa-font-display;
	src: url('#{$fa-font-path}/fa-brands-400.woff2') format('woff2'),
		url('#{$fa-font-path}/fa-brands-400.ttf') format('truetype');
}

.fab,
.#{$fa-css-prefix}-brands {
	font-family: 'Font Awesome 6 Brands';
	font-weight: 400;
}

@each $name, $icon in $fa-brand-icons {
	.#{$fa-css-prefix}-#{$name}:before {
		content: unquote('"#{ $icon }"');
	}
}
