//
// _header.scss
//

#page-topbar {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: 1002;
	background-color: $header-bg;
}

.navbar-header {
	display: flex;
	-ms-flex-pack: justify;
	justify-content: space-between;
	align-items: center;
	margin: 0 auto;
	height: $header-height;
	padding: 0 calc(#{$grid-gutter-width} / 2) 0 0;

	.dropdown.show {
		.header-item {
			background-color: rgba($dark, 0.05);
		}
	}
}

.navbar-brand-box {
	padding: 0 0.5rem;
	text-align: center;
	width: $navbar-brand-box-width;
}

.logo {
	line-height: 70px;
	display: block;
	position: relative;

	.logo-sm {
		display: none;
	}

	.logo-lg,
	.logo-sm {
		width: 100%;
		position: relative;
	}

	.logo-lg img,
	.logo-sm img {
		width: 100%;
		height: auto;
		max-width: 150px;
	}
}

.logo-light {
	display: none;
}

.logo-dark {
	.logo-lg {
		display: block;
		max-width: 250px;
	}
}

/* Search */

.app-search {
	padding: calc(#{$header-height - 38px} / 2) 0;

	.form-control {
		border: none;
		height: 38px;
		padding-left: 20px;
		padding-right: 20px;
		background-color: $topbar-search-bg;
		box-shadow: none;
		border-radius: 30px;
	}
	span {
		position: absolute;
		z-index: 10;
		font-size: 13px;
		line-height: 38px;
		right: 13px;
		top: 0;
		color: $header-item-color;
	}
}

// Mega menu

.megamenu-list {
	li {
		position: relative;
		padding: 5px 0px;
		a {
			color: $dropdown-color;
		}
	}
}

@media (max-width: 992px) {
	.navbar-brand-box {
		width: auto;
		max-width: 80px;
	}

	.logo {
		span.logo-lg {
			display: none;
		}

		span.logo-sm {
			display: inline-block;
		}
	}
}

.page-content {
	padding: calc(#{$header-height}) calc(#{$grid-gutter-width} / 2)
		$footer-height calc(#{$grid-gutter-width} / 2);
}

.header-item {
	height: $header-height;
	box-shadow: none !important;
	color: $header-item-color;
	border: 0;
	border-radius: 0px;

	&:hover {
		color: $header-item-color;
	}
}

.header-profile-user {
	height: 32px;
	width: 32px;
	background-color: $gray-300;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
}

.header-profile-user .actual-image {
	display: block;
	object-fit: cover;
	min-width: 100%;
	min-height: 100%;
	width: auto;
	height: auto;
}

.noti-icon {
	i {
		font-size: 24px;
		color: $header-item-color;
	}

	.badge {
		position: absolute;
		top: 17px;
		right: 7px;
	}
}

.notification-item {
	.notification {
		display: flex;

		i {
			font-size: 0.7rem;
			padding: 0.7rem;
		}
	}

	.media {
		padding: 0.75rem 1rem;

		.media-body {
			h6 {
				color: $gray-600;
				font-weight: 400;
				font-size: 0.8rem;

				strong {
					color: $primary;
					font-weight: 700;
				}
			}
		}

		&:hover {
			background-color: $gray-100;
		}
	}
}

// Dropdown with Icons
.dropdown-icon-item {
	display: block;
	border-radius: 3px;
	line-height: 34px;
	text-align: center;
	padding: 15px 0 9px;
	display: block;
	border: 1px solid transparent;
	color: $gray-600;

	img {
		height: 24px;
	}

	span {
		display: block;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	&:hover {
		background-color: $gray-100;
	}
}

// Full Screen
.fullscreen-enable {
	[data-toggle='fullscreen'] {
		.bx-fullscreen::before {
			content: '\ea3f';
		}
	}
}

body[data-topbar='dark'] {
	#page-topbar {
		background-color: $header-dark-bg;
	}
	.navbar-header {
		.dropdown.show {
			.header-item {
				background-color: rgba($white, 0.05);
			}
		}
	}

	.header-item {
		color: $header-dark-item-color;

		&:hover {
			color: $header-dark-item-color;
		}
	}

	.header-profile-user {
		background-color: rgba($white, 0.25);
	}

	.noti-icon {
		i {
			color: $header-dark-item-color;
		}
	}

	.logo-dark {
		display: none;
	}

	.logo-light {
		display: block;
		max-width: 250px;
	}

	.app-search {
		.form-control {
			background-color: rgba($topbar-search-bg, 0.07);
			color: $white;
			border: 1px solid rgba($white, 0.07);
		}
		span,
		input.form-control::-webkit-input-placeholder {
			color: rgba($white, 0.5);
		}
	}
}

body[data-sidebar='dark'] {
	.navbar-brand-box {
		background: $sidebar-dark-bg;
	}

	.logo-dark {
		display: none;
	}

	.logo-light {
		display: block;
		max-width: 250px;
	}
}

@media (max-width: 600px) {
	.navbar-header {
		.dropdown {
			position: static;

			.dropdown-menu {
				left: 10px !important;
				right: 10px !important;
			}
		}
	}
}

@media (max-width: 380px) {
	.navbar-brand-box {
		display: none;
	}
}

body[data-layout='horizontal'] {
	.navbar-header {
		padding: 0 calc(#{$grid-gutter-width} / 2);
	}

	.navbar-brand-box {
		width: auto;
		padding-left: 0px;
	}
	.page-content {
		margin-top: $header-height;
		padding: calc(55px) calc(#{$grid-gutter-width} / 2) $footer-height
			calc(#{$grid-gutter-width} / 2);
	}
}

@media (max-width: 992px) {
	body[data-layout='horizontal'] {
		.navbar-brand-box {
			width: auto;
			padding-left: 5px;
		}
		// #page-topbar {
		//     padding: 0px;
		// }
		.page-content {
			margin-top: 10px;
		}
	}
}
