/*!
 * Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 * Copyright 2022 Fonticons, Inc.
 */
@import './custom/plugins/icons/functions';
@import './custom/plugins/icons/variables';

:root,
:host {
	--#{$fa-css-prefix}-font-thin: normal 100 1em/1 '#{ $fa-style-family }';
}

@font-face {
	font-family: 'Font Awesome 6 Pro';
	font-weight: 300;
	font-style: normal;
	font-weight: 100;
	font-display: $fa-font-display;
	src: url('#{$fa-font-path}/fa-thin-100.woff2') format('woff2'),
		url('#{$fa-font-path}/fa-thin-100.ttf') format('truetype');
}

.fat,
.#{$fa-css-prefix}-thin {
	font-family: 'Font Awesome 6 Pro';
	font-weight: 300;
	font-weight: 100;
}
