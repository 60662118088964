//
// _buttons.scss
//

button,
a {
	outline: none !important;
}

// Rounded Buttons
.btn-rounded {
	border-radius: 30px;
}

.btn-dark {
	color: $gray-200 !important;
}

.btn-outline-dark {
	&:hover {
		color: $gray-200 !important;
	}
}

.btn {
	border: 0px solid rgba($white, 0);

	&:hover,
	&:active,
	&:focus {
		border: 0px solid rgba($white, 0);
	}
}
